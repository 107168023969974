import axios from "axios";
import {baseApiVersion, headers, locationBaseUrl} from "../config/env";

const documentPolicyBaseUrl = '/location/document/policy/'

export function fetchLocationDocumentPolicyCreate(data) {
    var tt=  locationBaseUrl+baseApiVersion+documentPolicyBaseUrl
    console.log(tt);
    return  axios.post(locationBaseUrl+baseApiVersion+documentPolicyBaseUrl,
        data,
        { headers });
}