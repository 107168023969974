<style scoped>
.smallJoinBox > h1 {
  font-size: 1.4rem;
}
.smallJoinBox > h3 {
  font-size: 1.2rem;
  color: #969696;
  margin-bottom: 20px !important;
}
.termsBox {
  max-height: 300px; /* 스크롤 높이 조절 */
  overflow-y: auto; /* 내부 스크롤 적용 */
  padding: 10px;
  border: 1px solid #ddd;
  background: #fff;
}
.fixedBottom {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #ddd;
}
.termsWrap > div span {
  font-size: 1.2rem !important;
}
.buttonWrap > .joinBtn {
  background: #0080FF;
  border: 0;
  border-radius: 5px;
}
.buttonWrap > .cancelBtn {
  border: 1px solid #ddd;
  color: #969696;
}
</style>

<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>

    <div class="baseBox smallBaseBox">
      <div class="joinBox smallJoinBox">
        <h1>위치기반 서비스 이용약관 동의</h1>

        <!-- 약관 내용 스크롤 가능 박스 -->
        <div class="termsBox">
          <h2 style="white-space: pre-wrap;">
            {{content}}
          </h2>
        </div>

        <!-- 동의 체크박스 및 버튼 (스크롤 시 고정) -->
        <div class="fixedBottom">
          <h1>
            <input type="checkbox" v-model="consent" />
            <span>위의 약관에 동의합니다.</span>
          </h1>
          <div class="buttonWrap">
            <button class="point large joinBtn" @click="submit()">
              {{ $t("btn-consent") }}
            </button>
            <button class="cancel large cancelBtn" @click="$router.go(-1)">
              {{ $t("btn-cancel") }}
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>




<script>
import { fetchLocationDocumentList, fetchLocationDocumentSearch} from "@/api/locationDocument";
import {fetchLocationDocumentPolicyCreate} from "@/api/locationDocumentPolicy";
import {fetchUserDetails} from "@/api/user";
//import { } from "@/api/locationDocumentPolicy";
fetchLocationDocumentList,fetchLocationDocumentSearch
export default {
  name: "LocationPolicy.vue",
  data(){
    return {
      consent : false,
      content : "이용약관내용들어감",
      country : "Korea",
      language : "Ko",
      document : null,
      userId : null,
      token : null,
    }
  },
  created() {
    this.token = this.$route.query.token
    this.userId =  this.$route.query.userId
    if(this.token != null && this.token != undefined){
      localStorage.setItem("token", this.token);
    }
    if(this.userId != null && this.userId != undefined){
      localStorage.setItem("userId", this.userId);
    }


    },
  mounted() {
    this.getLocationDocumentSearch()
    this.getUserDetails()
  },
  methods : {
    getUserDetails() {
      let userId = localStorage.getItem("userId");
      fetchUserDetails(userId).then((res) => {
        if (res.data.result == 0) {
          this.userId = res.data.data.userId;
        }
      });
    },
    getLocationDocumentSearch(){
      const params = {
        country : this.country,
        language : this.language,
        isUse : true,
      }
      fetchLocationDocumentSearch(params).then((res)=> {
        console.log(res)
        if(res.data.result == 0){
          this.document = res.data.data.content[0]
          this.content = this.document.content
          console.log(this.document)
        }
      })
    },
    submit(){
      if(this.consent){
        const data = {
          userId : this.userId,
          country: this.document.country,
          version : this.document.version,
          language : this.document.language,
          accept : this.consent
        }
        fetchLocationDocumentPolicyCreate(data).then((res) =>{
          console.log(res);
          alert("동의하셨습니다. \n 이전화면으로 돌아가주세요")
        })
      }else{
        alert("동의 후 진행해주세요.")
      }
    }
  },
}
</script>

