import axios from "axios";
import { baseApiVersion, headers ,locationBaseUrl } from "../config/env";

const documentBaseUrl = '/location/document'
export function fetchLocationDocumentList(){
    return axios.get(
        locationBaseUrl+baseApiVersion+documentBaseUrl+'/list',{
            headers
        }
    )
}

export function fetchLocationDocumentSearch(params){
    return axios.get(
        locationBaseUrl+baseApiVersion+documentBaseUrl+'/list/search',{
            params,
            headers
        }
    )
}
